
// @ts-nocheck
import locale_es_46json_9a8369d3 from "#nuxt-i18n/9a8369d3";
import locale_en_45EN_46json_07b5745e from "#nuxt-i18n/07b5745e";
import locale_de_45DE_46json_377187c9 from "#nuxt-i18n/377187c9";
import locale_it_45IT_46json_9ce046a3 from "#nuxt-i18n/9ce046a3";
import locale_fr_45FR_46json_8afa7d8d from "#nuxt-i18n/8afa7d8d";

export const localeCodes =  [
  "es",
  "en",
  "de",
  "it",
  "fr"
]

export const localeLoaders = {
  es: [
    {
      key: "locale_es_46json_9a8369d3",
      load: () => Promise.resolve(locale_es_46json_9a8369d3),
      cache: true
    }
  ],
  en: [
    {
      key: "locale_en_45EN_46json_07b5745e",
      load: () => Promise.resolve(locale_en_45EN_46json_07b5745e),
      cache: true
    }
  ],
  de: [
    {
      key: "locale_de_45DE_46json_377187c9",
      load: () => Promise.resolve(locale_de_45DE_46json_377187c9),
      cache: true
    }
  ],
  it: [
    {
      key: "locale_it_45IT_46json_9ce046a3",
      load: () => Promise.resolve(locale_it_45IT_46json_9ce046a3),
      cache: true
    }
  ],
  fr: [
    {
      key: "locale_fr_45FR_46json_8afa7d8d",
      load: () => Promise.resolve(locale_fr_45FR_46json_8afa7d8d),
      cache: true
    }
  ]
}

export const vueI18nConfigs = []

export const nuxtI18nOptions = {
  restructureDir: "i18n",
  experimental: {
    localeDetector: "",
    switchLocalePathLinkSSR: false,
    autoImportTranslationFunctions: false,
    typedPages: true,
    typedOptionsAndMessages: false,
    generatedLocaleFilePathFormat: "absolute",
    alternateLinkCanonicalQueries: false,
    hmr: true
  },
  bundle: {
    compositionOnly: true,
    runtimeOnly: false,
    fullInstall: true,
    dropMessageCompiler: false,
    optimizeTranslationDirective: true
  },
  compilation: {
    strictMessage: true,
    escapeHtml: false
  },
  customBlocks: {
    defaultSFCLang: "json",
    globalSFCScope: false
  },
  locales: [
    {
      code: "es",
      country: "ES",
      name: "Español",
      files: [
        "/app/i18n/lang/es.json"
      ]
    },
    {
      code: "en",
      country: "GB",
      name: "English",
      files: [
        "/app/i18n/lang/en-EN.json"
      ]
    },
    {
      code: "de",
      country: "DE",
      name: "Deutsch",
      files: [
        "/app/i18n/lang/de-DE.json"
      ]
    },
    {
      code: "it",
      country: "IT",
      name: "Italiano",
      files: [
        "/app/i18n/lang/it-IT.json"
      ]
    },
    {
      code: "fr",
      country: "FR",
      name: "Français",
      files: [
        "/app/i18n/lang/fr-FR.json"
      ]
    }
  ],
  defaultLocale: "es",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  trailingSlash: false,
  defaultLocaleRouteNameSuffix: "default",
  strategy: "prefix",
  lazy: false,
  langDir: "lang",
  rootRedirect: undefined,
  detectBrowserLanguage: {
    alwaysRedirect: true,
    cookieCrossOrigin: false,
    cookieDomain: null,
    cookieKey: "i18n_redirected",
    cookieSecure: false,
    fallbackLocale: "es",
    redirectOn: "root",
    useCookie: true
  },
  differentDomains: false,
  baseUrl: "",
  customRoutes: "page",
  pages: {},
  skipSettingLocaleOnNavigate: false,
  types: "composition",
  debug: false,
  parallelPlugin: false,
  multiDomainLocales: false,
  i18nModules: []
}

export const normalizedLocales = [
  {
    code: "es",
    country: "ES",
    name: "Español",
    files: [
      {
        path: "/app/i18n/lang/es.json",
        cache: undefined
      }
    ]
  },
  {
    code: "en",
    country: "GB",
    name: "English",
    files: [
      {
        path: "/app/i18n/lang/en-EN.json",
        cache: undefined
      }
    ]
  },
  {
    code: "de",
    country: "DE",
    name: "Deutsch",
    files: [
      {
        path: "/app/i18n/lang/de-DE.json",
        cache: undefined
      }
    ]
  },
  {
    code: "it",
    country: "IT",
    name: "Italiano",
    files: [
      {
        path: "/app/i18n/lang/it-IT.json",
        cache: undefined
      }
    ]
  },
  {
    code: "fr",
    country: "FR",
    name: "Français",
    files: [
      {
        path: "/app/i18n/lang/fr-FR.json",
        cache: undefined
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false
export const hasPages = true

export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18nInternal"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
/** client **/

/** client-end **/